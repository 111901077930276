
import { Options, Vue } from 'vue-class-component'
import FloatingFormItem from '@/components/DataEntry/FloatingLabel/FloatingFormItem.vue'
import ButtonValidationComponent from '@/components/General/Button/ButtonValidation.vue'
// eslint-disable-next-line
import { IAffiliateParams } from '@/intefaces/dashboardReseller'
import { mapActions } from 'vuex'
// eslint-disable-next-line
import { PropType, toRaw } from 'vue'
import Form from 'ant-design-vue/lib/form'

interface ICreateDate {
  userInfo: IAffiliateParams
  rules: unknown
  newAffiliate: Boolean
  loading: boolean
}

const rules = {
  firstname: [
    {
      label: 'Nom',
      required: true,
      message: 'Ce champ est obligatoire',
      trigger: 'blur',
    },
  ],
  lastname: [
    {
      label: 'Prénom',
      required: true,
      message: 'Ce champ est obligatoire',
      trigger: 'blur',
    },
  ],
  email: [
    {
      label: 'email',
      required: true,
      message: 'Ce champ est obligatoire',
      trigger: 'blur',
    },
    {
      pattern: /^(.+)@(.+){2,}\.(.+){2,}$/,
      message: "L'email est invalide",
      trigger: 'change',
    },
  ],
  phone: [
    {
      label: 'Télephone',
      required: true,
      message: 'Ce champ est obligatoire',
      trigger: 'blur',
    },
  ],
  rib: [
    {
      label: 'RIB',
      required: true,
      message: 'Ce champ est obligatoire',
      trigger: 'blur',
    },
  ],
}

@Options({
  name: 'DilypseAffiliateModalCreate',
  props: {
    dataUser: {
      type: Object as PropType<ICreateDate['userInfo']>,
      default: () => ({}),
    },
    isNew: Object,
  },
  components: {
    FloatingFormItem,
    ButtonValidationComponent,
    AForm: Form,
  },
  watch: {
    dataUser: {
      immediate: true,
      handler(user) {
        if (user) {
          this.userInfo = user.record.affiliated
          this.userInfo.rib = this.userInfo.iban
          this.rules.email[0].readOnly = true
          this.newAffiliate = false
        }
      },
    },
    isNew: {
      handler(isNew) {
        this.rules.email[0].readOnly = false
        this.newAffiliate = isNew.status
        this.userInfo = {
          firstname: '',
          lastname: '',
          email: '',
          phone: '',
          rib: '',
        }
      },
    },
  },
  data(): ICreateDate {
    return {
      loading: false,
      newAffiliate: this.isNew || true,
      userInfo: this.dataUser || {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        rib: '',
      },
      rules,
    }
  },
  emits: ['created', 'cancel'],
  methods: {
    ...mapActions([
      'setNewAffiliate',
      'setDataAffiliatesList',
      'updateAffiliate',
    ]),
    async handleCreate() {
      try {
        this.loading = true
        await this.$refs.formRef.validate()
        const userInfo = toRaw(this.userInfo)

        if (this.newAffiliate) {
          await this.setNewAffiliate(userInfo)
        } else {
          delete userInfo.status
          await this.updateAffiliate(userInfo)
        }
        this.loading = false

        this.setDataAffiliatesList()
        this.resetFields()

        this.$emit('created', true)
      } catch (e) {
        this.loading = false
      }
    },
    resetFields() {
      const keysUserInfo = Object.keys(toRaw(this.userInfo))

      this.$refs.formRef.resetFields()
      this.$refs.formRef.clearValidate(keysUserInfo)
    },
    handleCancel() {
      setTimeout(() => this.resetFields())
      this.$emit('cancel')
    },
  },
})
export default class DilypseAffiliateModalCreate extends Vue {}
