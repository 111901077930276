<template>
  <div class="body-content">
    <dilypse-affiliate-statistic :data="data" />
    <dilypse-affiliate-list @action="handleAction" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { mapActions } from 'vuex'
import DilypseAffiliateStatistic from './DilypseAffiliateTab/DilypseAffiliateStatistic.vue'
import DilypseAffiliateList from './DilypseAffiliateTab/DilypseAffiliateList.vue'

@Options({
  name: 'DilypseResellerAffiliate',
  components: {
    DilypseAffiliateStatistic,
    DilypseAffiliateList,
  },
  mounted() {
    this.setDataAffiliatesList()
  },
  data() {
    return {
      data: null,
    }
  },
  methods: {
    ...mapActions(['setDataAffiliatesList']),
    handleAction(data: any) {
      this.data = data
    },
  },
})
export default class DilypseResellerAffiliate extends Vue {}
</script>
