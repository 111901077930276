
import { Options, Vue } from 'vue-class-component'
import { mapActions, mapGetters } from 'vuex'
import CardComponent from '@/components/DataDisplay/Card/Card.vue'
import TableComponent from '@/components/DataDisplay/Table/Table.vue'
import DropdownComponent from '@/components/Navigation/Dropdown/Dropdown.vue'

// eslint-disable-next-line
import { IDropdownProps } from '@/components/Navigation/Dropdown/interface'
import { toRaw } from 'vue'
import _ from 'lodash'
// eslint-disable-next-line
import { IAffiliatesDataResult } from '@/intefaces/dashboardReseller'
// eslint-disable-next-line
import { ISearchParams } from '@/intefaces/common'

interface DilypseAffiliateListData {
  options: IDropdownProps[]
}

@Options({
  name: 'DilypseAffiliateList',
  components: {
    CardComponent,
    TableComponent,
    DropdownComponent,
  },
  computed: {
    ...mapGetters(['getAffiliationList']),
    computedData() {
      return this.getAffiliationList || { datas: {} }
    },
    list() {
      return this.computedData
    },
  },
  data(): DilypseAffiliateListData {
    return {
      options: [
        {
          id: 1,
          label: 'Modifier',
        },
        {
          id: 2,
          label: 'Suspendre',
        },
      ],
    }
  },
  emits: ['action'],
  methods: {
    ...mapActions(['updateAffiliateStatus', 'setDataAffiliatesList']),
    async handleChangeAction(id: number, record: IAffiliatesDataResult) {
      if (id === 1) {
        this.$emit('action', {
          type: 'edit',
          record: _.cloneDeep(toRaw(record)),
          timestamp: new Date().getTime(),
        })
      } else {
        record.affiliated.rib = record.affiliated.iban
        await this.updateAffiliateStatus({
          affiliate_id: record.affiliated.affiliate_id,
          status: !record.affiliated.status ? 1 : 0,
        })
        this.setDataAffiliatesList()
      }
    },
    handleRow(record: IAffiliatesDataResult) {
      return !record.affiliated.status ? 'disable-row' : ''
    },
    handleSort(sort: ISearchParams) {
      this.setDataAffiliatesList({
        key: sort.column.columnName,
        order: sort.order.replace('end', ''),
      })
    },
  },
})
export default class DilypseAffiliateList extends Vue {}
