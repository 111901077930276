<template>
  <card-component title="Liste de vos affiliés" class="affiliate-list">
    <table-component
      :columns="list.columns"
      :data="list.datas.result"
      class="affiliate-list"
      :rowClassName="handleRow"
      @change="handleSort"
    >
      <template v-for="c in list.columns" :key="c.key" #[c.key]="{ record }">
        <a
          v-if="c.key === 'email'"
          :class="c.key"
          :href="`mailto:${record.affiliated.email}`"
          title=""
          >{{ record.affiliated[c.key] }}</a
        >
        <span v-else-if="c.key === 'subscribe'" :class="c.key">
          {{ record.record.count }}
        </span>
        <span v-else-if="c.key === 'subscribe_freemium'" :class="c.key">
          {{ record.record.count_freemium }}
        </span>
        <span v-else-if="c.key === 'gains'" :class="c.key">
          {{ record.record.gain }}
        </span>
        <span v-else-if="c.key === 'actions'">
          <dropdown-component
            :options="[
              {
                id: 1,
                label: 'Modifier',
              },
              {
                id: 2,
                label: !record.affiliated.status ? 'Activer' : 'Suspendre',
              },
            ]"
            :show-label="false"
            @click="({ id }) => handleChangeAction(id, record)"
          >
            <template #suffix>
              <span></span>
            </template>
            <template #prefix>
              <i class="dot"></i>
            </template>
          </dropdown-component>
        </span>
        <span v-else :class="c.key">
          {{ record.affiliated[c.key] }}
        </span>
      </template>
    </table-component>
  </card-component>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { mapActions, mapGetters } from 'vuex'
import CardComponent from '@/components/DataDisplay/Card/Card.vue'
import TableComponent from '@/components/DataDisplay/Table/Table.vue'
import DropdownComponent from '@/components/Navigation/Dropdown/Dropdown.vue'

// eslint-disable-next-line
import { IDropdownProps } from '@/components/Navigation/Dropdown/interface'
import { toRaw } from 'vue'
import _ from 'lodash'
// eslint-disable-next-line
import { IAffiliatesDataResult } from '@/intefaces/dashboardReseller'
// eslint-disable-next-line
import { ISearchParams } from '@/intefaces/common'

interface DilypseAffiliateListData {
  options: IDropdownProps[]
}

@Options({
  name: 'DilypseAffiliateList',
  components: {
    CardComponent,
    TableComponent,
    DropdownComponent,
  },
  computed: {
    ...mapGetters(['getAffiliationList']),
    computedData() {
      return this.getAffiliationList || { datas: {} }
    },
    list() {
      return this.computedData
    },
  },
  data(): DilypseAffiliateListData {
    return {
      options: [
        {
          id: 1,
          label: 'Modifier',
        },
        {
          id: 2,
          label: 'Suspendre',
        },
      ],
    }
  },
  emits: ['action'],
  methods: {
    ...mapActions(['updateAffiliateStatus', 'setDataAffiliatesList']),
    async handleChangeAction(id: number, record: IAffiliatesDataResult) {
      if (id === 1) {
        this.$emit('action', {
          type: 'edit',
          record: _.cloneDeep(toRaw(record)),
          timestamp: new Date().getTime(),
        })
      } else {
        record.affiliated.rib = record.affiliated.iban
        await this.updateAffiliateStatus({
          affiliate_id: record.affiliated.affiliate_id,
          status: !record.affiliated.status ? 1 : 0,
        })
        this.setDataAffiliatesList()
      }
    },
    handleRow(record: IAffiliatesDataResult) {
      return !record.affiliated.status ? 'disable-row' : ''
    },
    handleSort(sort: ISearchParams) {
      this.setDataAffiliatesList({
        key: sort.column.columnName,
        order: sort.order.replace('end', ''),
      })
    },
  },
})
export default class DilypseAffiliateList extends Vue {}
</script>

<style scoped>
.email {
  color: #0062ff;
}
.created,
.iban {
  color: #757491;
}
</style>

<style lang="scss">
.affiliate-list {
  .ant-table-align-center:nth-last-of-type(2),
  .ant-table-align-center:nth-last-of-type(3),
  .ant-table-align-center:nth-last-of-type(4) {
    .ant-table-column-title {
      font-size: 11px;
      line-height: 14px;
      display: inline-block;
    }
  }
}
.affiliate-list {
  .ant-table tbody tr td:last-child {
    padding-right: 10px;
  }
}
</style>
