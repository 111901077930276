<template>
  <div class="d-flex justify-content-between align-items-center mb-4">
    <div class="d-flex">
      <month-statistic month="Mois d'avril 2021" class="mr-5" />
      <side-card-component
        :count="computedData.datas.total_subscribed || 0"
        icon="task"
        class="mr-5"
      >
        souscription(s) <br />
        généré(s)
      </side-card-component>
      <side-card-component
        :count="computedData.datas.total_subscribed_freemium || 0"
        icon="task"
        class="mr-5"
      >
        souscription(s) <br />
        freemium
      </side-card-component>
      <side-card-component
        :count="computedData.datas.total_gain || 0"
        unit="€"
        icon="calculator"
        class="mr-5"
      >
        # Gagné(s)
      </side-card-component>
    </div>
    <button class="btn btn-primary" @click="onCreateNew">
      Créer un affilié
    </button>
    <modal-component v-model="visible">
      <dilypse-affiliate-modal-create
        :isNew="isNew"
        :data-user="data"
        @created="onCreated"
        @cancel="visible = false"
      />
    </modal-component>
    <modal-component v-model="success">
      <dilypse-affiliate-modal-success @continue="success = false" />
    </modal-component>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { mapGetters } from 'vuex'

import SideCardComponent from '@/components/DataDisplay/SideCard/SideCard.vue'
import MonthStatistic from '@/components/DataDisplay/MonthStatistic/MonthStatistic.vue'
import ModalComponent from '@/components/Feedback/Modal/Modal.vue'

import DilypseAffiliateModalCreate from './DilypseAffiliateModalCreate.vue'
import DilypseAffiliateModalSuccess from './DilypseAffiliateModalSuccess.vue'

interface IDilypseAffiliateStatisticData {
  visible: boolean
  success: boolean
  dataUser: unknown
  isNew: unknown
}

@Options({
  name: 'DilypseAffiliateStatistic',
  components: {
    SideCardComponent,
    MonthStatistic,
    DilypseAffiliateModalCreate,
    ModalComponent,
    DilypseAffiliateModalSuccess,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    data(data) {
      this.dataUser = data
      this.visible = true
    },
  },
  computed: {
    ...mapGetters(['getAffiliationList']),
    computedData() {
      return this.getAffiliationList || { datas: {} }
    },
  },
  data(): IDilypseAffiliateStatisticData {
    return {
      visible: false,
      success: false,
      dataUser: {},
      isNew: {
        timestamp: false,
        status: false,
      },
    }
  },
  methods: {
    onCreateNew() {
      this.isNew = {
        timestamp: new Date().getTime(),
        status: true,
      }
      this.visible = true
    },
    onCreated() {
      this.visible = false
      this.success = true
    },
  },
})
export default class DilypseAffiliateStatistic extends Vue {}
</script>
