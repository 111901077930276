
import { Options, Vue } from 'vue-class-component'
import { mapGetters } from 'vuex'

import SideCardComponent from '@/components/DataDisplay/SideCard/SideCard.vue'
import MonthStatistic from '@/components/DataDisplay/MonthStatistic/MonthStatistic.vue'
import ModalComponent from '@/components/Feedback/Modal/Modal.vue'

import DilypseAffiliateModalCreate from './DilypseAffiliateModalCreate.vue'
import DilypseAffiliateModalSuccess from './DilypseAffiliateModalSuccess.vue'

interface IDilypseAffiliateStatisticData {
  visible: boolean
  success: boolean
  dataUser: unknown
  isNew: unknown
}

@Options({
  name: 'DilypseAffiliateStatistic',
  components: {
    SideCardComponent,
    MonthStatistic,
    DilypseAffiliateModalCreate,
    ModalComponent,
    DilypseAffiliateModalSuccess,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    data(data) {
      this.dataUser = data
      this.visible = true
    },
  },
  computed: {
    ...mapGetters(['getAffiliationList']),
    computedData() {
      return this.getAffiliationList || { datas: {} }
    },
  },
  data(): IDilypseAffiliateStatisticData {
    return {
      visible: false,
      success: false,
      dataUser: {},
      isNew: {
        timestamp: false,
        status: false,
      },
    }
  },
  methods: {
    onCreateNew() {
      this.isNew = {
        timestamp: new Date().getTime(),
        status: true,
      }
      this.visible = true
    },
    onCreated() {
      this.visible = false
      this.success = true
    },
  },
})
export default class DilypseAffiliateStatistic extends Vue {}
